import { AppConfig, Environment } from 'shared-domain';

import { environment } from '../environments/environment';
import { companyFields } from './company';
import { countries } from './countries';
import { affiliatedCompanyFields } from './affiliated-company';
import { individualFields } from './individual';
import { stepsConfig } from './step';

export const bootstrapConfig: Partial<Omit<AppConfig, 'environment'>> & {
  environment: Environment;
} = {
  websiteTitle: 'Inscription Easytransac',
  languages: ['fr', 'en'],
  showPoweredLogo: false,
  redirectUrl:
    'https://wh-hprod-1.easytransac.com/redirection/dotfile/?id=EXTERNAL_ID',
  countries,
  companyFields,
  affiliatedCompanyFields,
  individualFields,
  stepsConfig,
  environment,
};
