export const countries = [
  {
    code: 'DE',
    iso3: 'DEU',
    name: 'Germany',
    localName: 'Deutschland',
    continent: 'Europe',
    emoji: '🇩🇪',
    emojiCode: '1F1E9 1F1EA',
  },
  {
    code: 'BE',
    iso3: 'BEL',
    name: 'Belgium',
    localName: 'Belgien',
    continent: 'Europe',
    emoji: '🇧🇪',
    emojiCode: '1F1E7 1F1EA',
  },
  {
    code: 'CY',
    iso3: 'CYP',
    name: 'Cyprus',
    localName: 'Κύπρος',
    continent: 'Europe',
    emoji: '🇨🇾',
    emojiCode: '1F1E8 1F1FE',
  },
  {
    code: 'ES',
    iso3: 'ESP',
    name: 'Spain',
    localName: 'España',
    continent: 'Europe',
    emoji: '🇪🇸',
    emojiCode: '1F1EA 1F1F8',
  },
  {
    code: 'EE',
    iso3: 'EST',
    name: 'Estonia',
    localName: 'Eesti',
    continent: 'Europe',
    emoji: '🇪🇪',
    emojiCode: '1F1EA 1F1EA',
  },
  {
    code: 'FR',
    iso3: 'FRA',
    name: 'France',
    localName: 'France',
    continent: 'Europe',
    emoji: '🇫🇷',
    emojiCode: '1F1EB 1F1F7',
  },
  {
    code: 'GP',
    iso3: 'GLP',
    name: 'Guadeloupe',
    localName: 'Guadeloupe',
    continent: 'North America',
    emoji: '🇬🇵',
    emojiCode: '1F1EC 1F1F5',
  },
  {
    code: 'GF',
    iso3: 'GUF',
    name: 'French Guiana',
    localName: 'Guyane française',
    continent: 'South America',
    emoji: '🇬🇫',
    emojiCode: '1F1EC 1F1EB',
  },
  {
    code: 'IT',
    iso3: 'ITA',
    name: 'Italy',
    localName: 'Italia',
    continent: 'Europe',
    emoji: '🇮🇹',
    emojiCode: '1F1EE 1F1F9',
  },
  {
    code: 'LV',
    iso3: 'LVA',
    name: 'Latvia',
    localName: 'Latvija',
    continent: 'Europe',
    emoji: '🇱🇻',
    emojiCode: '1F1F1 1F1FB',
  },
  {
    code: 'LT',
    iso3: 'LTU',
    name: 'Lithuania',
    localName: 'Lietuva',
    continent: 'Europe',
    emoji: '🇱🇹',
    emojiCode: '1F1F1 1F1F9',
  },
  {
    code: 'LU',
    iso3: 'LUX',
    name: 'Luxembourg',
    localName: 'Luxemburg',
    continent: 'Europe',
    emoji: '🇱🇺',
    emojiCode: '1F1F1 1F1FA',
  },
  {
    code: 'MT',
    iso3: 'MLT',
    name: 'Malta',
    localName: 'Malta',
    continent: 'Europe',
    emoji: '🇲🇹',
    emojiCode: '1F1F2 1F1F9',
  },
  {
    code: 'MQ',
    iso3: 'MTQ',
    name: 'Martinique',
    localName: 'Martinique',
    continent: 'North America',
    emoji: '🇲🇶',
    emojiCode: '1F1F2 1F1F6',
  },
  {
    code: 'YT',
    iso3: 'MYT',
    name: 'Mayotte',
    localName: 'Mayotte',
    continent: 'Africa',
    emoji: '🇾🇹',
    emojiCode: '1F1FE 1F1F9',
  },
  {
    code: 'NL',
    iso3: 'NLD',
    name: 'Netherlands',
    localName: 'Nederland',
    continent: 'Europe',
    emoji: '🇳🇱',
    emojiCode: '1F1F3 1F1F1',
  },
  {
    code: 'PL',
    iso3: 'POL',
    name: 'Poland',
    localName: 'Polska',
    continent: 'Europe',
    emoji: '🇵🇱',
    emojiCode: '1F1F5 1F1F1',
  },
  {
    code: 'PF',
    iso3: 'PYF',
    name: 'French Polynesia',
    localName: 'Polynésie française',
    continent: 'Oceania',
    emoji: '🇵🇫',
    emojiCode: '1F1F5 1F1EB',
  },
  {
    code: 'PT',
    iso3: 'PRT',
    name: 'Portugal',
    localName: 'Portugal',
    continent: 'Europe',
    emoji: '🇵🇹',
    emojiCode: '1F1F5 1F1F9',
  },
  {
    code: 'RE',
    iso3: 'REU',
    name: 'Réunion',
    localName: 'La Réunion',
    continent: 'Africa',
    emoji: '🇷🇪',
    emojiCode: '1F1F7 1F1EA',
  },
  {
    code: 'GB',
    iso3: 'GBR',
    name: 'United Kingdom',
    localName: 'United Kingdom',
    continent: 'Europe',
    emoji: '🇬🇧',
    emojiCode: '1F1EC 1F1E7',
  },
  {
    code: 'CH',
    iso3: 'CHE',
    name: 'Switzerland',
    localName: 'Suisse',
    continent: 'Europe',
    emoji: '🇨🇭',
    emojiCode: '1F1E8 1F1ED',
  },
];
