import { useMemo } from 'react';
import { omit } from 'lodash';
import { Box, Button, VStack, Textarea, Input } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Select } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const details_des_activites = 'details_des_activites';
const website_url = 'website_url';
const volume_previsionnel_en_euros_par_mois =
  'volume_previsionnel_en_euros_par_mois';
const volume_previsionnel_en_euros_par_mois_options = [
  'inferieur_a_1000',
  'de_1000_a_5000',
  'de_5000_a_10000',
  'de_10000_a_50000',
  'de_50000_a_100000',
  'superieur_a_100000',
];

const validationSchema = Yup.object({
  [details_des_activites]: Yup.string().min(50, ' ').required(),
  [website_url]: Yup.string()
    .url('Website must be a valid URL with protocol (http or https)')
    .optional(),
  [volume_previsionnel_en_euros_par_mois]: Yup.array(
    Yup.mixed().oneOf(volume_previsionnel_en_euros_par_mois_options),
  )
    .min(1)
    .required('Please pick one choice'),
});

export const AdditionalCompanyData = () => {
  const stepId = useStepId();

  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata, company } = useStore();

  const default_website_url = company?.website_url || '';
  const default_volume_previsionnel_en_euros_par_mois =
    company?.custom_properties?.[volume_previsionnel_en_euros_par_mois] || '';
  const defaultValues = useMemo(() => {
    return {
      [details_des_activites]: metadata?.[details_des_activites] || '',
      [website_url]: default_website_url,
      [volume_previsionnel_en_euros_par_mois]:
        default_volume_previsionnel_en_euros_par_mois,
    };
  }, [
    metadata,
    default_website_url,
    default_volume_previsionnel_en_euros_par_mois,
  ]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    const metadata = omit(
      formData,
      [volume_previsionnel_en_euros_par_mois],
      [website_url],
    );
    submitCustomStepForm({
      caseMetadata: metadata,
      companyData: {
        ...company,
        name: company?.name ?? null,
        country: company?.country ?? null,
        registration_number: company?.registration_number ?? null,
        website_url: formData[website_url],
        custom_properties: {
          ...company?.custom_properties,
          [volume_previsionnel_en_euros_par_mois]:
            formData[volume_previsionnel_en_euros_par_mois],
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={details_des_activites}
          label={t(`steps.${stepId}.${details_des_activites}.label`)}
          helper={t(`steps.${stepId}.${details_des_activites}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Textarea maxW="400px" resize="vertical" {...f} />;
          }}
        />

        <GroupController
          name={website_url}
          label={t(`steps.${stepId}.${website_url}.label`)}
          isRequired={false}
          control={control}
          render={(f) => {
            return (
              <Input
                type="url"
                placeholder="https://mysite.com"
                maxW="400px"
                {...f}
              />
            );
          }}
        />

        <GroupController
          name={volume_previsionnel_en_euros_par_mois}
          label={t(
            `steps.${stepId}.${volume_previsionnel_en_euros_par_mois}.label`,
          )}
          isRequired={true}
          control={control}
          render={(f) => (
            <Select
              stepId={stepId}
              name={volume_previsionnel_en_euros_par_mois}
              onChange={(value: string[]) => {
                setValue(volume_previsionnel_en_euros_par_mois, [value] ?? [], {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={volume_previsionnel_en_euros_par_mois_options}
              defaultValue={f.value}
              hasOtherOption={false}
            />
          )}
        />

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
