import { BaseStepIdEnum, StepConfig, StepTypeEnum } from 'shared-domain';
import { AdditionalCompanyData } from '../app/components/additional-company-data-v2';

export const stepsConfig: StepConfig[] = [
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'additional_company_data',
    type: StepTypeEnum.custom_component,
    component: AdditionalCompanyData,
  },
  {
    id: BaseStepIdEnum.affiliated_companies_list,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: false },
  },
  {
    id: 'pdf_viewer_terms',
    type: StepTypeEnum.text,
  },
];
